import { clsx } from 'clsx';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useId,
  useState,
} from 'react';
import {
  input,
  inputCharacterCount,
  inputContainer,
  inputContainerWithAfter,
  inputContainerWithBefore,
  inputError,
  inputLabel,
  inputVariants,
} from './input.css.ts';

export interface InputProps extends ComponentPropsWithoutRef<'input'> {
  errorMessage?: string;
  before?: ReactNode;
  after?: ReactNode;
  label?: string;
  inputClassName?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  function InputComponent(
    {
      className,
      inputClassName,
      errorMessage,
      maxLength,
      value,
      defaultValue,
      before,
      after,
      onChange,
      label,
      id,
      ...rest
    },
    ref,
  ) {
    const reactId = useId();
    const inputId = id || reactId;

    const [count, setCount] = useState<number | null>(
      ((value || defaultValue) as string)?.length || 0,
    );

    const trackCharacterCount: React.ChangeEventHandler<HTMLInputElement> = (
      e,
    ) => {
      setCount(e.target.value.length);
    };

    return (
      <div>
        {label && (
          <label htmlFor={inputId} className={inputLabel}>
            {label}
          </label>
        )}
        <div
          className={clsx(
            inputContainer,
            inputVariants[errorMessage ? 'error' : 'default'],
            before && inputContainerWithBefore,
            (maxLength || after) && inputContainerWithAfter,
            className,
          )}
        >
          {before}
          <input
            type="text"
            ref={ref}
            id={inputId}
            className={clsx(input, inputClassName)}
            value={value}
            maxLength={maxLength}
            onChange={(e) => {
              if (maxLength) {
                trackCharacterCount(e);
              }
              onChange?.(e);
            }}
            {...rest}
          />
          {after}
          {maxLength ? (
            <span className={inputCharacterCount}>
              {count}/{maxLength}
            </span>
          ) : null}
        </div>
        {errorMessage && <p className={inputError}>{errorMessage}</p>}
      </div>
    );
  },
);
